<template>
  <NewProductItem
    v-if="item.storeInfo?.title"
    v-tap="getAnalysis('tap')"
    v-expose="getAnalysis('expose')"
    :style="compStyle"
    class="fashion-store__product-card"
    :class="{ 'fashion-store__product-card-right': constantData?.GB_cssRight }"
    :language="language"
    :constant-data="constantData"
    :item="item"
    :index="index"
    :column="2"
    :config="itemConfig"
    @clickItem="clickItem(item)"
    @mounted="productItemMounted"
  >
    <template #cardBottom>
      <div class="fashion-store__slot">
        <Icon
          v-if="item.storeInfo?.isNewIcon"
          name="sui_icon_shop_flat2_14px_color"
          size="14px"
        />
        <Icon
          v-else
          name="sui_icon_shop_flat_14px_color"
          size="14px"
        />
        <div class="fashion-store__text">
          {{ item.storeInfo?.title || '' }}
        </div>
      </div>
    </template>
  </NewProductItem>
</template>
<script>
import { defineComponent, computed } from 'vue'
import { useRouter } from 'public/src/pages/common/RiskCommon/utils.js'
import { Icon } from '@shein-aidc/icon-vue2'
import NewProductItem from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'
export default defineComponent({
  name: 'FashionStoreContent',
  components: {
    NewProductItem,
    Icon
  },
  props: {
    language: {
      type: Object,
      default: (() => {})
    },
    item: {
      type: Object,
      default: (() => {})
    },
    index: {
      type: Number,
      default: 0
    },
    config: {
      type: Object,
      default: (() => {}) 
    },
    constantData: {
      type: Object,
      default: (() => {})
    },
    /**
     * 埋点上报字段--基于指令式埋点，wiki:910812171
     * exposeId 曝光埋点id
     * clickId 点击埋点id
     * data: 埋点上报的数据
     */
    analysisData: {
      type: Object,
      default: (() => {})
    },
    /**
     * url扩展参数
     * 示例：&src_module=xxx&src_identifier=xxx&src_tab_page_id=xxx
     */
    urlExtendParams: {
      type: String,
      default: ''
    },
    // 商卡样式覆盖
    compStyle: {
      type: Object,
      default: (() => {})
    },
  },
  setup (props) {
    const router = useRouter()
    const itemConfig = computed(() => {
      // 时尚店铺内容体商卡配置覆盖
      const specialConfig = {
        ...props.config,
        // 不展示标题
        showTitle: false,
        showTitleTwoLine: false,
        // 不展示加车按钮
        showAddBagBtn: false,
        // 隐藏服务类型标签
        hideServiceLabels: true,
        // 榜单不可点击
        rankingLabelClickable: false,
        // 不展示趋势标签
        showDeliveryWords: false,
        // 不展示历史低价、保留款跟价款标签
        showPriceDiscountDesc: false,
        // 不跳转到商详
        disableMainimgJump: true,
      }
      return specialConfig
    })

    const getAnalysis = (type) => {
      return {
        ...props.analysisData,
        id: type === 'expose' ? props.analysisData.exposeId : props.analysisData.clickId,
      }
    }
    const productItemMounted = (componetInstance) => {
      componetInstance?.setElAttr?.() // 设置埋点属性在dom上
    }

    const clickItem = async (item) => {
      const { goods_id, store_code, cat_id } = item
      const { langPath } = props.constantData
      let url = `${langPath}/store/home?store_code=${store_code}&adp=${goods_id}&main_goods_id=${goods_id}&main_cate_id=${cat_id}&tabId=allItem&isStoreTabSticky=1${props.urlExtendParams}&rule_poskey=ChannelShopItemList`
      router.push(url)
    }

    return {
      itemConfig,
      getAnalysis,
      clickItem,
      productItemMounted,
    }
  },
})
</script>


<style lang="less">
.fashion-store__product-card {
  --product-card__bottom-wrapper: 0.16rem 0.2133rem;
  .product-card__selling-proposition-text {
    background-color: transparent !important;
  }
  .product-card__sales-label {
    background-color: transparent !important;
  }
  .product-card__bottom-wrapper {
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/06/21/31/1718954832de72e530b9d9075c97c5afc83299ec80.png');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  // 时尚店铺内容体屏蔽 组合购
  .buy-box__container {
    display: none;
  }
  // 时尚店铺内容体屏蔽 一键购
  .one-click-container {
    display: none;
  }
  // 融合隐藏加车按钮
  .product-card__add-btn {
    display: none;
  }
}
.fashion-store__slot {
  display: flex;
  align-items: center;
  height: 0.3733rem;
  margin-top: 0.16rem;
}
.fashion-store__text {
  margin-left: 4px;
  color: #9462FF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // font-family: "SF Pro";
  font-size: 0.2933rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fashion-store__product-card-right {
  .product-card__bottom-wrapper {
    // ar站点样式
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/06/21/e9/17189549082050116c44d3cbac38662aa02177aedd.png');
  }
}
</style>

