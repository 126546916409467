import schttp from 'public/src/services/schttp'

// 是否展示调查问卷组件
export const showSurveyComponent = (surveyInfo, groupNum = 1) => {
  const { surveyId, loseConfig: { days, times } = {}, exposureMark = false  } = surveyInfo || {}
  if (!surveyId) return false
  if (exposureMark) return true // 已经曝光过的问卷不隐藏
  const surveyIds = JSON.parse(localStorage.getItem('surveyIds') || null) || []
  const surveyExposeInfo = JSON.parse(localStorage.getItem('surveyExposeInfo') || null) || []
  const groupNumIsExpose = sessionStorage.getItem(`survey_group${groupNum}`)
  const currentTime = new Date().getTime()
  const surveyExposeData =  surveyExposeInfo.filter(item => getTimeInterval(currentTime, item.exposeTime) <= +days)

  // 如果曝光次数小于配置次数，且当前问卷未填写，且当前问卷组未曝光过，则展示
  return surveyExposeData.length < times && !surveyIds.includes(surveyId) && !groupNumIsExpose
}


const getTimeInterval = (currentTime, recordTime) => {
  return (currentTime - recordTime) / (1000 * 60 * 60 * 24)
}

// 获取调查问卷信息
export const getSurveyInfo = async (params) => {
  const data = await schttp({
    method: 'GET',
    url: '/category/position/voucher_survey',
    useBffApi: true,
    params,
  })
  return data
}

